footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    height: 17rem;
    background-color: black;

}

.footer-links {
    display: flex;
    gap: 3rem;
    text-align: center;
    flex-wrap: wrap;
    justify-content: center;
}

.footer-links a {
    color: white;
    text-decoration: none;
    font-size: 1.8rem;
}

.footer-links a:hover {
    text-decoration: underline;
}

.love {
    color: white;
    font-size: 1.7rem;
    margin-top: 3rem;
}